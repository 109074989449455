$color: #2B4B80;
.root {
  .header {
    display: flex;
    height: 70px;
    justify-content: flex-start;
    align-items: center;
    background-color: #EBF2FF;
    padding: 0 8px;
    button {
      color: $color;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .main {
    padding: 24px;
    .preview_wrap {
      text-align: center;
      .filePreview_wrap {
        height: 583px;
        overflow: auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .btn_download {
        margin-top: 20px;
        background-color: $color;
        color: #fff;
        border-color: $color;
      }
    }
    .edit_wrap {
      > * {
        padding: 5px 0;
      }
      .name {
        height: 47px;
        line-height: 47px;
        color: $color;
        font-size: 28px;
        font-weight: bold;
      }
      .rating_wrap {
        .icon_box {
          display: flex;
          justify-content: space-around;
          flex-grow: 1;
          padding: 0 16px;
          img {
            width: 70px;
            height: 70px;
            filter: grayscale(100%);
            border-radius: 50%;
          }
          .active {
            border: 3px solid #2B4B80;
            filter: grayscale(0%);
          }
        }
      }
      .audio_wrap {
        display: flex;
        flex-wrap: wrap;
        .audio_box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-grow: 1;
        }
      }
      .text_wrap {
        .text_area_box {
          border: 1px solid #707070;
          .text_area {
            border-radius: 0px;
            border-color: transparent;
          }
          .tag_box {
            padding: 8px;
          }
        }
      }
      .file_wrap {
        .img_preview_box {
          display: flex;
          justify-self: start;
          align-items: center;
          height: 66px;
          border: 1px solid #707070;
          padding: 8px;
          cursor: pointer;
          img {
            width: 48px;
            height: 48px;
            object-fit: cover;
            flex-shrink: 0;
          }
          .preview_text {
            flex-grow: 1;
            margin-left: 8px;
            font-size: 18px;
            color: #7D7D7D;
          }
        }
      }
      .rank_wrap {
        // display: flex;
        // align-items: center;
        .label {
          flex-shrink: 0;
        }
      }
    }
    .label {
      height: 34px;
      line-height: 34px;
      color: $color;
      font-size: 20px;
      font-weight: bold;
      margin-right: 10px;
    }
    .horizontal {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .upload_button {
      font-size: 18px;
      color: #7D7D7D;
      height: 42px;
      border-radius: 0px;
      border-color: #707070;
    }
    .delete_btn {
      background: #F1F3F4;
      border-color: #F1F3F4
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 82px;
    background-color: #EBF2FF;
    padding: 0 24px;
    .end_time {
      color: #707070;
      font-size: 20px;
    }
    button {
      background-color: $color;
      border-color: $color;
      color: #fff;
      width: 152px;
      height: 55px;
      font-size: 20px;
    }
  }
}