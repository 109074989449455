.box {
  margin: 100px auto;
  letter-spacing: 0px;
  text-align: center;

  .border {
    border-right: 1px solid #f0f0f0;
  }

  .title {
    font-weight: bold;
    color: #45a735;
  }

  .back {
    color: #707070;
    text-decoration: underline;
  }

  .message {
    font-weight: bold;
    color: #9b9b9b;
  }

  .space {
    margin: 0px 16px;
  }

  .underline {
    text-decoration: underline;
  }

  // .mask {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100vw;
  //   height: 100vh;
  //   background-color: #000000a3;
  //   z-index: 9999;
  // }
  .mask_inner {
    // position: absolute;
    // top: 40%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // color: #fff;
    font-size: 24px;
    font-weight: bold;
  }
}
