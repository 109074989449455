:global {
  .schoolResourceUpload_root {
    // display: inline-block;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .ant-upload {
      font-size: 18px;
      color: #7D7D7D;
    }
    .ant-upload-picture-card-wrapper {
      width: fit-content;
    }
    .ant-upload-select-picture-card {
      width: 245px !important;
      height: 69px !important;
      border: 1px solid #707070;
      border-radius: 0;
      background-color: #fff;
      margin: 0;
    }
    .ant-upload-list-picture-card-container, .ant-upload-list-item {
      width: 69px !important;
      height: 69px !important;
      margin: 0;
    }
    .btn {
      width: 164px;
      height: 69px;
      margin: 0 17px;
      color: #fff;
      font-size: 18px;
      border-radius: 0;
    }
    // .submit_btn {
    //   background-color: #10975C;
    //   border: 1px solid #10975C;
    // }
    .submitted_btn {
      background-color: #919191;
      border: 1px solid #919191;
    }
    .upload_time {
      font-size: 18px;
      color: #919191;
    }
  }
  @media(max-width:768px) {
    .schoolResourceUpload_root{
      .ant-upload {
        font-size: 16px;
      }
      .ant-upload-select-picture-card {
        width: 200px !important;
        height: 50px !important;
      }
      .ant-upload-list-picture-card-container, .ant-upload-list-item {
        width: 50px !important;
        height: 50px !important;
      }
      .btn {
        width: 100px;
        height: 50px;
        font-size: 16px;
      }
    }
  }
}