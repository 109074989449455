.root {
  .img_box {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #ccc;
    .actions_mask {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(123, 123, 123, .7);
      padding: 0 28px;
      z-index: 10;
      transition: all 1s;
      .icon_wrap {
        width: 49px;
        height: 49px;
        background-color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 49px;
        cursor: pointer;
        img {
          width: 30px;
        }
      }
      .icon_wrap:hover {
        background-color: skyblue !important;
      }
      .icon_wrap:active {
        transform: scale(1.2);
      }
      .fullscreen_icon {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 26px;
        color: #fff;
        cursor: pointer;
      }
    }
    .medal_icon {
      position: absolute;
      top: 14px;
      left: 14px;
    }
    .creative_awards {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #29ABE2;
      color: #fff;
      font-size: 16px;
      padding: 3px 12px;
      img {
        vertical-align: text-bottom;
      }
    }
  }
  .name_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 5px;
    .name {
      height: 24px;
      line-height: 24px;
      font-size: 18px;
      color: #000000;
      margin: 0;
    }
    .actions_wrap {
      flex-shrink: 0;
      > div {
        float: left;
        margin-left: 10px;
        > img {
          width: 18px;
          vertical-align: middle;
        }
        > span {
          vertical-align: middle;
        }
      }
    }
  }
  .course_name {
    height: 21px;
    line-height: 21px;
    font-size: 16px;
    // font-weight: bold;
    color: #585858;
    margin: 0;
  }
}

.file_wrap {
  width: 100%;
  img, video {
    width: 100%;
  }
}