.registerForm {
  :global {
    .ant-btn-primary {
      font-size: 18px;
    }
    .ant-checkbox-wrapper {
      display : inline-flex;
      align-items:baseline;
      line-height:1.5715;
      .ant-checkbox {
        top: .2em;
      }
    }
    .ant-btn {
      height :54px;
    }

  }
  margin: 35px auto;
  width: 389px;

  @media only screen and (max-width: 576px) {
    width: 320px;
    max-width: 100%;
  }

  letter-spacing: 0px;
  .describe {
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    color: #45a735;
  }
  .describe2 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #404040;
  }
  .next {
    border-color : #73bc66;
    background: #73bc66 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 18px;
    // margin-bottom: 16px;
  }
  .back {
    font-size: 18px;
    color: #707070;
  }
}
