.box {
  margin: 50px auto;
  letter-spacing: 0px;
  text-align: center;

  .describe {
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    color: #45a735;
  }
  .describe2 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #404040;
  }
  .center {
    width: 130px;
    margin: 35px auto;
  }
  .addIcon {
    font-size: 130px;
    margin-bottom: 22px;
    color: #9b9b9b;
    cursor: pointer;
  }
  .add {
    font: 18px/27px Noto Sans CJK SC;
    color: #888888;
    margin-bottom: 16px;
  }

  .buttonGroup {
    margin: 0 auto;
    width: 385px;
    @media only screen and (max-width: 576px) {
      width: 320px;
      max-width: 100%;
    }
    text-align: center;
  }
  .buttonGroup > button {
    height :54px;
  }
  .next {
    border-color : #73bc66;
    background: #73bc66 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 18px;
    margin-bottom: 16px;
  }
  .back {
    font-size: 18px;
    color: #707070;
    margin-bottom: 16px;
  }
  .skip {
    color: #707070;
    text-decoration: underline;
    cursor:pointer;
  }
}
