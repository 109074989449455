.input_content {
  max-width: 350px;
  margin: 35px 0;
}

.input {
  margin-bottom: 16px;
}

.input_button {
  background-color: #11852c;
  border-color: #11852c;
  &:hover,
  &:focus {
    background-color: #11852c;
    border-color: #11852c;
  }
}

.block_padding {
  margin-top: 36px;
}

.main_title {
  color: #11852c;
  margin-bottom: 7px;
  font-size: 18px;
}

.select {
  width: 213px;
  :global {
    .ant-select-selection-selected-value {
      float: none;
      font-size: 16px;
      color: #707070;
      font-weight: 500;
    }
  }
}

.account {
  margin: 8px 0;
  font-size: 16px;
  color: #707070;
  font-weight: 500;
}

.content {
  margin-bottom: 16px;
}

.content_title {
  color: #8a8a8a;
  font-size: 18px;
}

.content_img {
  object-fit: contain;
  max-width: 240px;
}

.submit_button {
  background-color: #11852c;
  border-color: #11852c;
  max-width: 290px;
  margin-top: 36px;
  margin-bottom: 10px;
  &:hover,
  &:focus {
    background-color: #11852c;
    border-color: #11852c;
  }
}

.tips {
  font-size: 14px;
  color: #707070;
}

.modal_body {
  text-align: center;
  max-width: 300px;

  :global {
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      max-width: 300px;
      padding: 16px;
    }
  }
  h2:nth-of-type(1) {
    margin: 25px 0px;
    font-size: 20px;
  }
  h2:nth-of-type(2) {
    margin-bottom: 6px;
    font-size: 16px;
  }
  p {
    font-size: 18px;
  }
  button {
    width: 268px;
    height: 40px;
    margin-top: 3px;
  }
  button:nth-of-type(1) {
    background-color: #11852c;
    border-color: #11852c;
    &:hover {
      background-color: #11852c;
    }
  }
  button:nth-of-type(2) {
    background-color: #707070;
    border-color: #707070;
    &:hover {
      background-color: #707070;
    }
  }
}
