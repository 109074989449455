.banner {
  position: relative;
  background-size: cover;
  color: #fff;
  .inner {
    max-width: 1450px;
    margin: 0 auto !important;
    .blur {
      padding-top : 30px;
      padding-bottom : 40px;
    }
    .blur2 {
      height:295px;
    }
    .blur2::before {
      content: "";
      height: 100%;
      left: 0;
      top: 0px;
      position: absolute;
      box-shadow: 20px 0px 50px 4.5rem #397dbc;
      z-index: 5;
    }
    .blur2::after {
      content: "";
      height: 100%;
      right: 0;
      top: 0;
      position: absolute;
      box-shadow: -20px 0px 50px 4.5rem #397dbc;
      z-index: 5;
    }
    .file:hover > div {
      opacity: 1;
    }
    .file {
      position: relative;
      text-align: right;
      div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: all .3s;
        button:nth-child(1) {
          margin-bottom: 10px;
        }
      }
    }
    .text {
      position: relative;
      .title {
        font-size: 50px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 1rem;
        overflow: hidden;
      }
      .description > *:first-child {
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        margin-bottom: 1.5rem;
        padding-right:4rem;
      }
      .description > *:not(:first-child) {
        display: none;
      }
      .set {
        opacity: 0;
        position: absolute;
        top: 0px;
        right: 0px;
        transition: all .3s;
        z-index: 10;
      }
    }
    .text:hover .set {
      opacity: 1;
    }
    .actionWrap {
      margin-bottom: 14px;
      .action {
        font-size: 18px;
        margin-right: 20px;
        .icon {
          width: 60px
        }
      }
    }
    .btnWrap {
      button {
        margin-right: 20px;
        color: #397dbc;
        border-color: #fff;
      }
      img {
        vertical-align: text-bottom;
        margin-right: 7px;
      }
    }
  }
  .changeBgBtnWrap {
    opacity: 0;
    position: absolute;
    top: 20px;
    left: 20px;
    transition: all .3s;
    button {
      margin: 0 5px;
    }
  }
}

.banner:hover .changeBgBtnWrap {
  opacity: 1;
}

.schoolCourseItem:hover  {
  .more {
    opacity: 1;
    height: 32px;
  }
  .move {
    top: 50px;
  }
}

.schoolCourseList {
  .inner {
    max-width: 1500px;
    margin: 0 auto !important;
    .courseItem {
      margin-bottom: 28px;
    }
  }
}

@media (min-width: 1200px) {
  .schoolCourseList .inner .courseItem {
    width: 20%;
  }
}

.schoolCourseItem {
  position: relative;
  height: 100%;
  padding: 6px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px #ccc;
  a {
    color: #000;
  }
  .imgWrap:after {
    content: "";
    display: block;
    border-radius: 10px;
    padding-bottom: 75%;
  }
  .imgWrap {
    position: relative;
    margin-bottom: 6px;
    .dim {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #2f2f2faa;
      pointer-events: none;
    }
    .hot {
      position: absolute;
      top: 0;
      left: 0;
      border-color: transparent #ff3939;
      border-width: 0px 0px 60px 60px;
      border-style: solid;
      img {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 8px;
        left: -54px;
      }
    }
    .file {
      width: 100%;
      object-fit: cover;
      border-radius: 10px 10px 0 0;
      height: 100%;
      position: absolute;
    }
    .title {
      position: absolute;
      width : 100%;
      height : 100%;
      top : 0;
      text-align : center;
      color : #000000bf;
      padding : 0.8rem;
      > div {
        width: 100%;
        height : 100%;
        display : flex;
        align-items: center;
        > div {
          flex : 1
        }
      }
    }
    .completed {
      position: absolute;
      top: 8px;
      left: 8px;
      color: #fff;
      background: #ff3434;
      font-size: 24px;
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      border-radius: 50%;
    }
  }
  .title {
    width: calc(100% - 70px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px;
    font-weight: bold;
    line-height: 25px;
  }
  .date{
    font-size: 13px;
  }
  .type {
    position: absolute;
    right : 0;
    bottom: 0;
    margin: 0;
  }
  .more {
    opacity: 0;
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 22px;
    line-height: 23px;
    transition: all .3s;
  }
  .actions {
    // opacity: 0;
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 22px;
    line-height: 23px;
    transition: all .3s;
    button {
      display: inline-block;
      margin-bottom: 6px;
    }
  }
}

.previewVideo:hover .previewVideoBtnWrap {
  opacity: 1;
}

.previewVideo {
  position: relative;
  .previewVideoBtnWrap {
    opacity: 0;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s;
    button {
      margin: 0px 5px;
    }
  }
}
