//新增课程里的课程资料页面样式

.bfEditor {
  border: 1px solid #bababa;
  :global {
    .bf-content {
      height: 200px;
    }
  }
}

:global {
  .form_container {
    .ant-form-item {
      margin-bottom: 7px;
      label {
        font-size: 12px;
        color: #989898;
      }
    }
    .ant-radio-group {
      margin-top: 10px;
      .ant-radio-button-wrapper {
        // padding: 0 27px;
        width: 33.3%;
        text-align: center;
        span {
          font-size: 12px;
        }
      }
      .ant-radio-button-wrapper:first-child {
        border-radius: 10px 0 0 10px;
      }
      .ant-radio-button-wrapper:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
    .publish_container {
      padding: 10px 0 20px 0;
      .courseList_container {
        height: 252px;
        overflow: auto;
        padding: 0 0.5rem;
        .close {
          position: absolute;
          top: 0px;
          right: 0px;
          z-index: 3;
          width: 22px;
          height: 22px;
          background: url("../image/cross.gif") no-repeat;
          cursor: pointer;
        }
      }
      .courseList_container::-webkit-scrollbar {
        width: 6px;
      }
      /* 滚动槽 */
      .courseList_container::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      /* 滚动条滑块 */
      .courseList_container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
      }
    }
    .button_container {
      button {
        background: #2b4b80;
        border-color: #2b4b80;
        font-size: 12px;
      }
      .ant-btn-primary[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        box-shadow: none;
      }
    }
    .editor::-webkit-scrollbar {
      width: 6px;
    }
    /* 滚动槽 */
    .editor::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    /* 滚动条滑块 */
    .editor::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .details_container {
    background: rgb(235, 243, 255);
    padding: 0 15px;
    h3 {
      margin: 0;
      height: 35px;
      line-height: 35px;
      font-weight: bold;
      color: #2b4b80;
    }
    h4 {
      margin: 0;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      font-weight: bold;
      color: #2b4b80;
    }
    p {
      font-size: 12px;
      color: #2b4b80;
    }
  }
}
