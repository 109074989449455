// 样式冗余过多,需要优化.
.box {
  margin: 50px auto;
  min-height: 400px;
  letter-spacing: 0px;
  padding : 0 16px;

  :global {
    .ant-form-item-label > label {
      font-size: 18px;
      font-weight: bold;
      color: #9b9b9b;
    }
    .ant-radio-group > .ant-radio-wrapper > span {
      font-size: 16px;
      font-weight: bold;
      color: #9b9b9b;
    }
  }

  .reverse {
    @media only screen and (max-width: 576px) {
      flex-direction: column-reverse;
    }
  }

  .form {
    min-height : 400px;
    @media only screen and (max-width: 576px) {
      min-height: unset;
    }
    display:flex;
    flex-direction: column;
  }

  .border {
    border-right: 1px solid #f0f0f0;
    @media only screen and (max-width: 576px) {
      border-right : 0;
    }
  }
  .tnc {
    margin-bottom:20px;
    padding : 0;
    color: #9b9b9b;
  }

  .describe {
    font-size: 18px;
    font-weight: bold;
    color: #9b9b9b;
  }

  .shop {
    font-size: 18px;
    font-weight: bold;
    color: #45a735;
  }

  .message {
    font-size: 18px;
    font-weight: bold;
    color: #9b9b9b;
  }

  .price {
    font-size: 24px;
    font-weight: bolder;
    color: #45a735;
  }

  .input {
    width: 70%;
  }

  .pay_method {
    display:block;
    height : 35px;
    margin:15px 0;
    text-transform: capitalize;
  }
  .pay_method img {
    height:100%;
  }
  .payNow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: start;
    @media only screen and (max-width: 576px) {
      flex-direction: row;
      justify-content: space-around;
    }
  }

  .payNow > div{
    width : 159px;
    margin-bottom : 0;
    @media only screen and (max-width: 576px) {
      margin : 0 5px;
      width : auto;
    }
  }

  .buttonGroup {
    margin: 0 auto;
    width: 385px;
  }
  .next {
    border-color : #73bc66;
    background: #73bc66 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 18px;
    width: 150px;
    height: 42px;
    margin: 0 auto;
  }
  .back {
    font-size: 18px;
    color: #707070;
    margin: 0 auto;
    width: 150px;
    height: 42px;
    margin: 0 auto;
  }
}
