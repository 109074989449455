//ArticleInfo组件样式

:global {
  .list {
    .ant-list-split .ant-list-item {
      border-bottom: none;
    }
    .ant-list-item {
      display: block;
      padding: 0;
    }
  }
}

.slider {
  > button::before {
    color: #000;
  }
  > ul {
    bottom: -40px; 
  }
}

.banner1 {
  position: relative;
  background: url("../image/pHome_article.png")no-repeat center;
  height: 475px;
  display: flex;
  align-items: center;
  .mask{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
  }
}
@media (max-width: 376px) {
  .banner1 {
    height: 300px;
  }
}
.content {
  margin: 15px 0;
}
.article_img {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
.bg {
  background: #e7f5fe;
  border-radius: 15px;
  margin: 10px 15px;
  padding: 20px 18px;
  min-height: 332px;
  .textArea {
    margin-top: 12px;
  }
  .star{
    margin-bottom: 10px;
    h4{
      float: left;
      margin: 10px 10px 0 0;
    }
  }
  .face {
    .score {
      display: inline-block;
      width: 55px;
      text-align: center;
      cursor:pointer;
      img {
        width: 45px;
        margin: 0 auto;
        display: block;
      }
      p{
        font-size: 12px;
        margin: 0;
      }
    }
  }
  .answervalue{
    color: green;
  }
  .radioBlock {
    display: inline-block;
    // color: #d02c21;
    color: green;
    margin-left: 10px;
  }
  .radioNone {
    display: none;
  }
}
.commentbg {
  min-height: 50px;
}
.btn {
  // position: absolute;
  // bottom: 15px;
  // left: 50%;
  // transform: translateX(-50%);
  text-align: center;
  margin: 20px 0 0 0;
}
.comment {
  margin: 25px 0;
  .bg {
    margin: 10px 20px;
  }
}
.demo_infinite_container {
  overflow: auto; 
  height: 675px;
  margin: 0px 15px 0px 0px;
}
.demo_loading_container {
  position: absolute;
  bottom: 150px;
  width: 100%;
  text-align: center;
}
