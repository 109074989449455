:global {
  .popupStudent_wrap{
    .ant-modal-content {
      border-radius: 20px;
      overflow: hidden;
    }
    .button{
      margin: 20px 10px 0; 
      padding: 0 25px;
      border-radius: 25px; 
      color: #479eff;
      border: none;
    }
    .redButton{
      color: #01af62;
    }
    
  }
}
