.common-pdf-content {
  .react-pdf__Document {
    position: relative;
    &:hover {
      .mask {
        display: block;
      }
    }
    canvas {
      width: 100% !important;
    }
  }
  .mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.6;
    display: none;
    cursor: pointer;
    .camera-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 36px;
      color: #fff;
    }
  }
}
.modalWrapClass {
  .ant-modal-content {
    .ant-modal-body {
      overflow: auto;
      user-select: none;
    }
  }
}
