.box {
  width: 600px;
  @media only screen and (max-width: 576px) {
    width: 320px;
    max-width: 100%;
  }
  margin: 70px auto;
  .relative {
    position: relative;
    text-align: center;
    cursor: pointer;
  }
  .editButton {
    position: absolute;
    top: 0;
    right: 8px;
    background-color: #ccc;
    border: none;
    color: #fff;
  }
  .aequilatus {
    width: 100%;
  }
  .next {
    border-color : #73bc66;
    background: #73bc66 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 18px;
    margin-bottom: 16px;
    height: 54px;
    width:294px;
  }
  .back {
    font-size: 18px;
    color: #707070;
    height: 54px;
    width:294px;
  }
}
