.root {
  position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 200px;
  padding: 10px;
  box-shadow: 0px 0px 8px #888888;
  .num_box {
    position: absolute;
    top: -10px;
    right: -10px;
    min-width: 20px;
    height: 20px;
    padding: 0 8px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #f5222d;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 0 1px #fff;
    box-shadow: 0 0 0 1px #fff;
  }
  .file_box {
    flex-grow: 1;
    img, video {
      width: 100%;
    }
    h1{
      color: #aa4744;
      margin: 0;
    }
  }
  .vote_btn{
    margin-top: 15px;
    border-color: #45A735;
    color: #45A735;
  }
  .vote_btn:disabled {
    border-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
  }
}

// @media (min-width)