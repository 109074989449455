.button {
  color: #fff;
}

.fb {
  background-color: #2f54eb;
}

.wechat {
  background-color: #52c41a;
}

.phone {
  background-color: #139fcd;
}

.email {
  background-color: #ffbe43;
}

.icon {
  font-size: 20px;
  float: left;
  line-height: 32px;
}
