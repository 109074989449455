.TitleTipTitle {
 font-size: 2rem;
 line-height: 2.5rem;
 word-wrap: break-word;
 white-space: normal;
 word-break:break-all;    
 color:#000;
 padding: 1rem 0;
}
.TitleTipText {
  position: relative;
  border-radius: 20px;
  line-height: 1.5rem;
  height:100%;
  .set {
    position: absolute;
    display: none;
    top: 5%;
    right: 5%;
    width: 20px;
    height: 20px;
    background-color: rgba(0,0,0,0.8);
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
  }
  ul {
    padding-left: 20px;
    margin:0 auto;
    word-wrap: break-word;
    li {
      font-size: .8rem;
    }
  }
}
.TitleTipText:hover{
  .set{
    display: block;
  }
}

.editor::-webkit-scrollbar {
  width: 6px;
}
/* 滚动槽 */
.editor::-webkit-scrollbar-track {
  border-radius: 10px;
}
/* 滚动条滑块 */
.editor::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}