:global {
  .evi-player::before {
    padding-top: 56.25%;
  }
  .evi-player::before {
    display: block;
    content: "";
  }
  .evi-player > * {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .evi-player {
    width: 100% !important;
    position: relative;
    margin: 0 auto;
  }
  .evi-player > video {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border: 5px solid #3030304f;
  }
  .phoneLanguage .ant-select-selection,
  .phoneLanguage .ant-select-open,
  .phoneLanguage .ant-select-open:hover,
  .phoneLanguage .ant-select-selection:active,
  .phoneLanguage .ant-select-enabled {
    border: none;
    box-shadow: none;
    background-color: #fff;
  }

  .phoneLanguage .ant-select-selection__rendered {
    margin-left: 0;
  }
}
.header,
.footer {
  background-color: white !important;
}

.avatar-text {
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90px;
  display: block;
  font-size: 16px;
}

.eviDownload {
  // display: none;
  padding: 1rem;
  background: #caeaf7;
  * > img {
    width: 100%;
    height: 40px;
    object-fit: contain;
  }
  .close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0px;
    left: 17px;
  }
}
.headerWrap {
  width: 100%;
  position: fixed;
  z-index: 200;
}

.header {
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  width: 100%;
  height: auto;
  // height:76px;
  line-height: 76px;
  .inner {
    max-width: 1200px;
    margin: 0 auto;
  }
  .btnWidth {
    // width: 120px;
    height: 56px;
  }
  @media (max-width: 400px) {
    .btnWidth {
      width: 105px;
    }
  }
  @media (max-width: 350px) {
    .btnWidth {
      width: 87px;
    }
  }
}

.footer {
  border-top: 5px solid;
  border-image: linear-gradient(
      to right,
      #92d9f8 0%,
      #92d9f8 10%,
      #ff8ea6 10%,
      #ff8ea6 20%,
      #fff4de 20%,
      #fff4de 30%,
      #c7ff72 30%,
      #c7ff72 40%,
      #bd9cff 40%,
      #bd9cff 50%,
      #92d9f8 50%,
      #92d9f8 60%,
      #ff8ea6 60%,
      #ff8ea6 70%,
      #fff4de 70%,
      #fff4de 80%,
      #c7ff72 80%,
      #c7ff72 90%,
      #bd9cff 90%,
      #bd9cff 100%
    )
    5;
  padding-top: 0;
  padding-bottom: 0;
  padding: 0;
  z-index: 1;
}
