:global {
  .ant-statistic-content {
    font-size: 16px;
  }
}
.header {
  height: 100%;
  padding: 0;
  .bar {
    background: #00bcd4;
    line-height: 40px;
    button:first-child {
      margin-right: 15px;
    }
    button:last-child {
      border: none;
      background: none;
      color: #fff;
      font-size: 16px;
    }
    .header_right {
      float: right;
      a {
        color: #fff;
      }
    }
  }
  .statistic {
    display: inline-block;
    text-align: right;
    line-height: 40px;
    padding-top: 15px;
    img {
      float: right;
      margin: 8px 8px 0;
    }
  }
  .modal {
    .ant-modal-header {
      background: #00bcd4;
    }
  }
  .menu {
    line-height: 72px;
    background: none;
    color: #fff;
    font-weight: bolder;
    float: right;
  }
}
.Icon {
  font-size: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}
// .bg1 {
//   background: url("../image/bg1.jpg");
// }
// .bg2 {
//   background: url("../image/bg2.jpg");
// }
.banner {
  width: 100%;
  position: relative;
  .bg {
    width: 100%;
    height: auto;
    z-index: 1;
  }
  .contentLeft {
    left: 10%;
  }
  .contentRight {
    left: 10%;
  }
  .contentBanner {
    position: absolute;
    top: 30%;
    z-index: 5;
    h1 {
      color: #ff5252;
      font-size: 66px;
      line-height: 82px;
      margin: 0;
      span {
        color: #5d2999;
      }
    }
    h3 {
      color: #ff5252;
      font-size: 24px;
      line-height: 41px;
      font-weight: 500;
      margin: 0px;
      padding: 0px;
    }
    p {
      font-weight: 500;
      visibility: inherit;
      line-height: 24px;
      margin: 0px;
      padding: 0px;
      font-size: 15px;
    }
    .btnBanner {
      color: #fff;
      background-color: #5d2999;
      border-color: #5d2999;
      margin-top: 25px;
    }
  }
}
.mask{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255,255,255,0.9);
}

.selectItem{
  width: 20%;
}

.section {
  padding: 40px 17px;
  text-align: center;
  color: #707070;
  margin-bottom: 50px;
  .book {
    right: 0;
    margin: 0 auto;
    position: absolute;
    left: 0;
    top: -32px;
    height: 90px;
    width: 90px;
    i {
      font-size: 48px;
      line-height: 90px;
      color: #fff;
    }
  }
  img{
    height: 170px;
    width: auto;
  }
  h4 {
    width: 210px;
    height: 51px;
    font-size: 20px;
    color: #fff;
    font-weight: bolder;
    margin: 40px auto 20px;
    line-height: 51px;
  }
}
.slickdetail {
  padding: 35px 20px 20px;
  text-align: left;
  color: #fff;
  background: #3598db;
}

.slickdetail > h2 {
  text-align: center;
  color: #fff;
  font-weight: bolder;
}

.slickdetail > img {
  width: 90px;
  position: absolute;
  top: -40px;
  left: 0px;
}

.video_container{
  height: 469px;
  background: url("../image/landing_banner2.png")no-repeat center center;
  background-size: cover;
}

.video{
  position: relative;
  top: 50px;
  width: 100%;
}

@media (max-width: 768px) {
  .contentBanner {
    position: static !important;
    padding: 0 34px;
    h1 {
      font-size: 44px !important;
    }
  }
}
@media (min-width: 992px) {
  .item {
    width: 20%;
  }
}
