.title {
  text-align: center;
  background-color: #0050b3;
  color: #fff;
  padding: 10px 0;
}

.titleImg {
  text-align: center;
  margin-bottom: 20px;
  > img {
    width: 100%;
  }
}

.tipsTextLine {
  text-align: center;
  margin-top: 10px;
  line-height: 25px;
  font-size: 18px;
  > span {
    color: #bfbfbf;
  }
  > span:last-child {
    margin-right: 5px;
  }
}

.inputIcon {
  color: rgba(0, 0, 0, 0.25);
}
