$btncolor:#7bd4c4;

.logintimewarp {
  .bubbleTail {
    position: absolute;
    top: 90%;
    left: 30%;
    width: 0;
    height: 0;
    border-width:6px;
    border-style: solid;
    border-color: transparent;
    margin-bottom: 1px;
    border-top-width: 10px;
    border-top-color: currentColor;
    color: #fff;
  }

  .btnwarp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    button {
      margin-top: 10px;
    }

    .primary {
      background-color: #fff;
      border-color: #fff;
      color: #7BD4C4;
    }

    .active {
      background-color: #7BD4C4;
      border-color: #7BD4C4;
      color: #fff;
    }
  }

  .itemwarp {
    width: 30px;
    height: 20px;
    position: relative;
    transform: translate(-1rem, -23px);

    .main {
      border-radius: 5px;
      box-shadow: 0px 1px 3px 0px #bbbbbb;
      position: absolute;
      // left: -100%;
      text-align: center;
      width: 30px;
      background: #fff;
      // transform: translate(-4rem);
    }
  }
}