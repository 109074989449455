.modal {
  // overflow: hidden;
  top: 20px !important;
  > div:nth-child(2) {
    border-radius: 15px;
    border: 5px solid #29ABE2;
  }
  .root {
    padding: 100px 34px 34px;
    position: relative;
    .close {
      position: absolute;
      top: -30px;
      right: -30px;
      button {
        width: 60px;
        height: 60px;
        color: #fff;
        background-color: #F65A60;
        font-size: 30px;
        border: none;
      }
    }
    .title {
      position: absolute;
      top: -42px;
      left: 50%;
      transform: translateX(-50%);
      width: 451px;
      img {
        width: 100%;
      }
    }
  }
}

@media (max-width:1200px) {
  .modal {
    .root {
      .close {
        top: -20px;
        right: -5px;
        button {
          width: 40px;
          height: 40px;
          font-size: 20px;
        }
      }
    }
  }
}

@media (max-width:576px) {
  .modal {
    .root {
      padding: 70px 34px 34px;
      .title {
        width: 350px;
        top: -36px;
      }
    }
  }
}

@media (max-width:375px) {
  .modal {
    .root {
      padding: 60px 24px 24px;
      .title {
        width: 230px;
        top: -26px;
      }
    }
  }
}