

:global{
  .article_container{
    .banner_container{
      img{
        width: 100%
      }
    }
    .btn_container{
      padding: 10px 0;
      background: #f3f3f3;
      .article_btn{
        float: left;
      }
      .article_btn > span{
        font-size: 18px;
        font-weight: bold;
      }
      .fixed_btn{
        float: right;
        button{
          background: #00b7a2;
          border-color: #00b7a2;
        }
      }
      @media(max-width: 576px) {
        .mb10{
          margin-bottom: 10px;
        }
        .left{
          float: left;
        }
      }
    }
    .list_container{
      padding: 30px 0;
      max-width: 1200px;
      .list_empty{
        width: 100%;
        height: 200px;
        border: 1px solid #eee;
        border-radius: 8px;
      } 
      .list_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgba(0, 0, 0, 0.65);
        .list_text{
          width: 60%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}