:global {
  .ant-modal-content {
    .ant-modal-body {
      .ant-card-body {
        padding: 0;
      }
    }
  }
}
//小贴士组件样式
.wrap {
  position: relative;
  .xs_title {
    h2 {
      background: #ff9d52;
      line-height: 80px;
      color: #fff;
      font-weight: bold;
      padding-left: 30px;
      font-size: 30px;
      margin: 0;
    }
  }
  .demo_infinite_container {
    border-radius: 4px;
    overflow: auto;
    padding: 8px 20px 8px 0;
    height: 345px;
    margin: 20px 55px 20px 30px;
    .card {
      border: none;
      .pic {
        width: 100%;
        height: 100px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .detail {
        padding-top: 5px;
        p {
          font-size: 0.9rem;
          line-height: 1.499;
          height: 32px;
          display: inline-block;
        }
        button {
          border: none;
          float: right;
          padding: 0 3px;
        }
      }
    }
  }
  .demo_loading_container {
    position: absolute;
    bottom: 150px;
    width: 100%;
    text-align: center;
  }
  .close {
    position: absolute;
    right: -19px;
    top: -14px;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    border: none;
    background: #ff9d52;

    i {
      color: #fff;
      font-size: 30px;
    }
  }
  @media (max-width: 768px) {
    .close {
      top: 0;
      right: 0;
    }
  }
}

/* 设置滚动条的样式 */
.demo_infinite_container::-webkit-scrollbar {
  width: 6px;
}
/* 滚动槽 */
.demo_infinite_container::-webkit-scrollbar-track {
  border-radius: 10px;
}
/* 滚动条滑块 */
.demo_infinite_container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
