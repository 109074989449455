.root {
  // max-width: 600px;
  text-align: center;
  padding-top: 50px;
  .btn {
    margin: 0 20px;
  }
  .select_btn {
    border: 1px solid #45a735;
    background: white;
    color: #45a735;
  }
}