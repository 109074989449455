:global {
  .manageModal {
    overflow: hidden;
    .ant-modal-close-x {
      color: #fff;
    }
    .ant-modal-content {
      border-radius: 20px;
      overflow: hidden;
      .ant-modal-body {
        padding: 0;
        .ant-card-body {
          padding: 0;
        }
      }
      .ant-modal-footer {
          text-align:center;
      }
    }
    .manage-container {
      background: #2b4b80;
      overflow: hidden;
      .title {
        margin: 0;
        font-size: 20px;
        height: 60px;
        line-height: 60px;
        color: #fff;
        padding: 0 20px;
      }
      .card-container {
        .ant-tabs-card {
          .ant-tabs-content {
            margin-top: -16px;
            .ant-tabs-tabpane {
              background: #fff;
              padding: 16px;
            }
          }
          .ant-tabs-bar {
            border-color: #fff;
            .ant-tabs-tab {
              color: #fff;
              border-color: transparent;
              background: transparent;
              padding: 0 30px;
              font-size: 12px;
            }
            .ant-tabs-tab-active {
              color: #2b4b80;
              border-color: #fff;
              background: #fff;
              border-radius: 10px 10px 0 0;
            }
          }
        }
      }
    }
  }
  .barChart{
    width: 40px;
    height: 40;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 20px;
    line-height: 40px;
    border-top: 1px solid #fff;
    text-align: center;
    cursor: pointer;
  }
  @media (max-width: 376px) {
    .barChart{
        width: 35px;
        height: 35px;
    }
  }
}

