.root {
  img,
  video,
  audio {
    width: 100%;
    height: auto;
  }
  .documentPage {
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
