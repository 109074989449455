.sortWrap {
  .tip {
    text-align: center;
  }
  .sortList {
    border: 1px solid #ccc;
    padding: 10px;
    .sortItemWrap {
      width: 20%;
    }
    .plusIcon {
      width: 100%;
      height: 107px;
      border: 2px dashed transparent;
      background: linear-gradient(white,white) padding-box, repeating-linear-gradient(-45deg,#ccc 0, #ccc 0.25em,white 0,white 0.75em);
      text-align: center;
      line-height: 125px;
      cursor: pointer;
    }
  }
}

.videoStore {
  .resourceListWrap {
    height: 429px; 
    overflow-y: auto; 
    overflow-x: hidden; 
    // margin: 10px 0;
    .resourceItem {
      .gradeIcon {
        background-color: #22b14c;
        margin: 0.2rem 0.1rem;
      }
      .typeIcon {
        background-color: #be10e9; 
        margin: 0.2rem 0.1rem;
      }
      .langIcon {
        background-color:#108ee9;
        margin: 0.2rem 0.1rem;
        cursor: pointer;
      }
    }
  }
  .videoListWrap {
    .loading {
      text-align: center;
    }
    .videoList {
      max-height: 508px; 
      overflow-y: auto;
    }
  }
}

.videoItem {
  position: relative;
  .icon {
    position: absolute; 
    z-index: 10; 
    font-size: 24px; 
  }
}