.box {
  margin: 50px auto 0;
  letter-spacing: 0px;
  text-align: center;

  .select {
    width: 280px;
  }

  .images {
    margin: 35px 0;
  }

  .img {
    cursor: pointer;
    border-radius: 20px;
    margin-bottom:1rem;
    &:hover {
      border: 2px #73bc66 solid;
    }
  }
  .describe {
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    color: #45a735;
  }
  .describe2 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #404040;
    max-width:650px;
    margin : 0 auto;
  }
  .checked {
    border: 2px #73bc66 solid;
  }

  .buttonGroup {
    margin: 0 auto;
    width: 385px;
  }
  .next {
    background: #73bc66 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 18px;
    margin-bottom: 16px;
  }
  .back {
    font-size: 18px;
    color: #707070;
    margin: 0 auto;
    width: 320px;
    max-width: 100%;
    height:54px;
  }
}
