.schoolCourseFilter_menu {
  .ant-menu-item {
    color: #000;
    border-bottom: 6px solid transparent;
  }
  .ant-menu-item-selected {
    border-bottom: 6px solid #397DBC;
  }
  .ant-menu-item:hover {
    border-bottom: 6px solid #397DBC;
  }
}