.box {
  position: relative;

  :global {
    @media (min-width: 1200px) {
      .ant-col-xl-4\.8 {
        width: 20%;
      }
    }
  }

  .loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    i{
      font-size: 50px;
    }
  }

  margin: 120px auto 128px;
  @media only screen and (max-width: 576px) {
    margin-top: 60px;
  }
  max-width: 1150px;
  .watching {
    font-size: 60px;
    text-align: center;
    font-weight: bold;
    color: #7a7a7a;
    margin-left: -24px;
    margin-right: -24px;
  }
  .describe {
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    color: #45a735;
  }
  .describe2 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #404040;
  }
  .loadingFrame {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    align-content: space-around;
    flex-wrap: nowrap;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .hover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #608c58c7;
    color: white;
    margin: -5px 0;
  }
  .avatar {
    // margin-bottom: 22px;
    margin: 0 auto 22px;
  }
  .avatar.clickable {
    cursor: pointer;
  }
  .avatar:hover {
    border: 6px solid #ebebeb;
  }
  .avatar.current {
    border: 6px solid #45a735;
    cursor: disabled;
  }
  .name {
    font-size: 18px;
    color: #888888;
    margin-bottom: 9px;
    text-align: center;
  }
  .edit {
    text-align: center;
    text-decoration: underline;
    // font: 18px/27px Noto Sans CJK SC;
  }
  .edit > button {
    width: 146px;
    height: 38px;
    line-height: 24px;
    font-size: 14px;
    padding: 6px 15px;
    border-radius: 5px;
  }
  .evi_parent {
    border-color: #4a98ff;
    background-color: #4a98ff;
    color: white;
  }
  .basic_vip {
    border-color: #4a98ff;
    background: #4a98ff 0% 0% no-repeat padding-box;
    color: white;
  }
  .join_evi {
    border: 1px solid #45a735;
    background: white;
    color: #45a735;
  }
  .add {
    text-align: center;
    cursor: pointer;
    text-align: center;
  }
  .addIcon {
    font-size: 76px;
    margin-top: 76px;
    margin-bottom: 22px;
    color: #45a735;
    cursor: pointer;
  }
  .addIcon.emptyFamily {
    margin-top: 22px;
  }
  .buttonGroup {
    margin: 0 auto;
    width: 385px;
    @media only screen and (max-width: 576px) {
      width: 320px;
      max-width: 100%;
    }
    text-align: center;
  }
  .buttonGroup > button {
    height: 54px;
  }
  .next {
    border-color: #73bc66;
    background: #73bc66 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 18px;
    margin-bottom: 16px;
  }
  .back {
    font-size: 18px;
    color: #707070;
  }
  .skip {
    color: #707070;
    text-decoration: underline;
    cursor: pointer;
  }
  .childRow {
    position: relative;
    min-height: 300px;
    @media only screen and (max-width: 576px) {
      min-height: unset;
      margin-bottom: 100px;
    }
  }
  .childRow.emptyFamily {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
.box.emptyFamily {
  margin-top: 30px;
}
