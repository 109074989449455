
.step {
  :global {
    // 图标样式
    .ant-steps-item-icon {
      width: 78px;
      height: 78px;
      border-radius: 50%;
      margin: 0 20px;
      background-color: #d2d2d2;
      border: none;

      @media only screen and (max-width: 576px) {
        width: 36px;
        height: 36px;
      }
      .ant-steps-icon {
        line-height: 78px;
        font-size: 31px;
        letter-spacing: 0px;

        @media only screen and (max-width: 576px) {
          line-height: 36px;
          font-size: 20px;
        }
      }
    }

    // .ant-steps-item-wait:last-child .ant-steps-item-icon {
      // margin: 0 auto;
    // }

    // 进度条样式
    .ant-steps-item-tail {
      margin: 20px 0 20px calc(20px + 76px);
      padding: 3.5px;
      @media only screen and (max-width: 576px) {
        margin:0 0 0 58px;
        padding:0;
        top:15px;
      }
      &::after {
        // width: 83%;
        height: 5px;
        @media only screen and (max-width: 576px) {
          height: 2px;
        }
      }
    }
    .ant-steps-item-content {

      @media only screen and (max-width: 576px) {
        display:none;
      }
    }

    // 选中时的样式
    .ant-steps-item-active {

      .ant-steps-item-icon {
        // border: 3px solid #45a735;
        border:none;
        background-color: #45a735;
        border-color: #45a735;
      }

      .ant-steps-item-content {
        .ant-steps-item-title {
          color: #45a735;
        }
      }
    }

    // 已完成样式
    .ant-steps-item-finish {
      .ant-steps-item-icon {
        border: 3px solid #45a735;

        background-color: #fff;
        border-color: #45a735;
        .ant-steps-icon {
          color: #45a735;
        }
      }

      // 进度条样式
      .ant-steps-item-tail::after {
        background-color: #45a735;
      }

      .ant-steps-item-content {
        .ant-steps-item-title {
          color: #45a735;
          font-weight: bold;
        }
      }
    }

    .ant-steps-item-wait {
      .ant-steps-item-icon {
        .ant-steps-icon {
          color: #FFFFFF;
        }
      }
    }

    // .ant-steps-item-finish
    //   > .ant-steps-item-container
    //   > .ant-steps-item-tail::after {
    //   background-color: #45a735;
    // }
  }
}
