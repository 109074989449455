.root {
  > div:nth-child(2) {
    border-radius: 15px;
    .header {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: 110px;
      // background-color: #10975C;
      padding: 0 34px;
      .logo {
        flex-shrink: 0;
        width: 62px;
        height: 62px;
      }
      .title {
        flex-grow: 1;
        color: #fff;
        margin-left: 22px;
        .name {
          height: 41px;
          font-weight: bold;
        }
        .time {
          height: 31px;
        }
      }
      .close_btn {
        flex-shrink: 0;
        font-size: 20px;
        color: #fff;
      }
    }
    .main {
      .loading {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      padding: 35px 0;
      .file_container {
        padding: 0 35px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .label {
          font-size: 20px;
          height: 40px;
          line-height: 40px;
          font-weight: bold;
          color: #000000;
        }
        .teaching_point {
          font-size: 18px;
          color: #000000;
        }
        .btn_box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          .submit_time {
            font-size: 18px;
            color: #919191;
            white-space: nowrap;
            flex-shrink: 0;
          }
        }
      }
      .commont_container {
        border-left: 1px solid #e1e1e1;
        padding: 0 35px;
        .comment_wrap {
          margin: -5px 0;
          > div {
            padding: 5px 0;
          }
          .label {
            font-size: 20px;
            height: 40px;
            line-height: 40px;
            font-weight: bold;
            color: #2b4b80;
          }
          audio {
            width: 100%;
          }
          .comment_icon {
            width: 75px;
            height: 75px;
            margin-left: 20px;
          }
          .comment_text {
            font-size: 18px;
            color: #707070;
          }
          .comment_tile {
            font-size: 16px;
            // color: #10975C;
          }
          .highlight_box {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            // color: #10975C;
            margin-top: 20px;
            span {
              margin-top: 10px;
            }
          }
        }
        .no_file,
        .no_comment {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          color: #707070;
          span {
            margin-top: 20px;
          }
        }
      }
      .file_name {
        font-size: 18px;
        color: #25a0ce;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.filePreview_wrap {
  width: 350px;
  height: 249px;
  border-radius: 7px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    // border-radius: 10px;
    object-fit: cover;
  }
}

@media (max-width: 992px) {
  .commont_container {
    margin-top: 35px;
  }
}

@media (max-width: 768px) {
  .root {
    > div:nth-child(2) {
      .header {
        padding: 0 10px;
      }
      .main {
        padding: 10px 0;
        .file_container {
          padding: 0 20px;
        }
        .commont_container {
          padding: 0 20px;
        }
      }
    }
  }
}
