.modal {
  :global {
    .ant-modal-content {
      background-color: transparent;
      color: #fff;
    }
  }
  h2,
  input {
    color: #fff;
    text-align: center;
  }

  input,
  button {
    background-color: transparent;
  }

  input {
    margin: 16px 0;
    font-size: 20px;
  }

  button {
    margin: 8px 0;
  }

  button:last-child {
    color: #fff;
  }
}

.describe {
  :global {
    .ant-modal-content {
      // background-color: transparent;
      color: #fff;
    }
    .ant-modal-body {
      padding: 8px;
      height: 650px;
      position: relative;
    }
    .ant-modal-confirm-btns {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      > .ant-btn {
        width: 50%;
        padding: 0;
        margin: 0;
        border: none;
        border-top: 1px solid #ccc;
        background-color: #fff;
        color: #40a9ff;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        font-size: 18px;
      }
      > .ant-btn:first-child {
        border-right: 1px solid #ccc;
      }
    }
  }
  h2 {
    text-align: center;
  }
}

.hint {
  :global {
    .ant-modal-body {
      height: 190px;
      text-align: center;
    }
    .ant-modal-confirm-btns {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
      > .ant-btn:first-child {
        display: none;
      }
      > .ant-btn:last-child {
        width: 100%;
        background-color: #fff;
        border: none;
        color: #40a9ff;
        padding: 0px;
        margin: 8px 0px 0px;
        border-top: 1px solid #ccc;
      }
    }
  }
}
