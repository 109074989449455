.root {
  height: 100%;
  @media (max-width: 1160px) {
    width: 100% !important;
  }
  :global {
    .ant-modal-body {
      padding: 8px 24px;
    }
    .ant-modal-content {
      background-color: transparent;
    }
    .ant-modal-close-x {
      background: #1864cc;
      border-radius: 50%;
      color: white;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      & > img {
        width: 24px;
      }
      @media (min-width: 1200px) {
        position: fixed;
        top: 10px;
        right: 20px;
      }
      @media (max-width: 700px) {
        width: 35px;
        height: 35px;
        & > img {
          width: 14px;
        }
      }
    }
  }
  h1 {
    margin-bottom: 0;
  }
  .title_text_wrap {
    position: relative;
    z-index: 88;
    display: flex;
    align-items: center;
    padding: 15px 35px;
    margin: 16px 0 0;
    border-radius: 50px;
    background-color: #1864cc;
    height: 100%;
  }
  .title_text {
    flex-grow: 1;
    font-size: 24px;
    color: #fff;
    @media (max-width: 700px) {
      padding-right: 5px;
      font-size: 16px;
    }
  }
  .title_play {
    cursor: pointer;
    margin-right: 16px;
    @media (max-width: 700px) {
      margin-right: 8px;
    }
  }
  .header {
    justify-content: space-around;
    margin-bottom: 8px;
    @media (max-width: 700px) {
      justify-content: start;
    }
  }
  .idea_img {
    width: 108px;
    height: 108px;
    @media (max-width: 700px) {
      width: 58px;
      height: 58px;
    }
  }
  .question_title {
    font-weight: bold;
    font-size: 32px;
    @media (max-width: 700px) {
      font-size: 18px;
      width: 220px;
      flex-wrap: wrap;
      margin-left: 10px;
    }
  }
  .answer_content {
    position: relative;
    top: -15px;
    margin-bottom: 20px;
    // background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.73);
    background-color: #fff;
    padding-top: 40px;
    padding-bottom: 30px;
    border-radius: 80px;
    width: 92%;
    margin: 0 auto !important;
    @media (max-width: 1200px) {
      padding-bottom: 50px;
    }
    :global {
      @media (min-width: 1200px) {
        .ant-col:nth-of-type(-n + 2) {
          margin-bottom: 60px;
        }
      }
    }
  }
  .answer_item {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding-bottom: 56.2%;
  }
  .answer_circle {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    color: #fff;
    font-size: 50px;
    line-height: 80px;
    text-align: center;
    background-color: #5b448c;
  }
  .option_logo {
    z-index: 10000;
    position: absolute;
    top: -10px;
    left: -30px;
    width: 70px;
    height: 70px;
    object-fit: contain;
    @media (max-width: 425px) {
      top: -30px;
      left: -25px;
    }
  }
  .answer_img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 10px;
  }
  .confirm_btn {
    width: 207px;
    height: 63px;
    line-height: 63px;
    text-align: center;
    background-color: #27ae60;
    box-shadow: 0 9px #1e7922;
    font-size: 30px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
  }
  .lang_button {
    position: absolute;
    top: 30px;
    right: 70px;
    z-index: 10000;
    @media (max-width: 425px) {
      top: 100px;
      right: 3px;
    }
  }

  .error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }

  .video {
    position: absolute;
    top: 155px;
    left: 0;
    z-index: 10000;
    @media (max-width: 576px) {
      top: 180px;
    }
  }
  .bg_content {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    @keyframes myMove {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(30px);
      }
    }
    & img:nth-of-type(1) {
      position: absolute;
      top: 10px;
      left: 10px;
      animation: myMove 4s linear 1s infinite alternate;
      @media (max-width: 700px) {
        width: 100px;
      }
    }
    & img:nth-of-type(2) {
      position: absolute;
      right: 40px;
      top: 10px;
      animation: myMove 4s linear 0.5s infinite alternate;
      @media (max-width: 700px) {
        width: 100px;
      }
    }
  }
}
