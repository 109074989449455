.box {
  margin: 50px auto;
  letter-spacing: 0px;
  text-align: center;

  :global {
    .ant-btn-primary {
      font-size: 18px;
    }
  }
  .title {
    font-size: 28px;
    font-weight: bold;
    color: #45a735;
  }

  .img {
    margin: 16px auto;
    display: block;
    height: 100px;
    width: auto;
    @media only screen and (max-width: 576px) {
      height : 50px
    }
    @media only screen and (max-width: 320px) {
      height : 40px
    }
  }

  .logo_describe {
    color: #3b3b3b;
    font-weight: bold;
    font-size: 20px;
    @media only screen and (max-width: 576px) {
      font-size:14px;
    }
  }

  .describe {
    text-align: left;

    margin: 30px auto;
    width: 300px;
    font-weight: bold;
    color: #404040;
  }

  .buttonGroup {
    margin: 0 auto;
    max-width: 385px;
    @media only screen and (max-width: 576px) {
      width: 320px;
      max-width: 100%;
    }
  }
  .buttonGroup > button {
    height: 54px;
  }
  .next {
    background: #73bc66 0% 0% no-repeat padding-box;
    border-color: #73bc66;
    color: #fff;
    font-size: 18px;
  }
  .back {
    margin-top: 16px;
    font-size: 18px;
    color: #707070;
  }
}
