.card {
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
}
// 全局
.personalCenterRoot {
  .header {
    width: 100%;
    height: 189px;
    background-image: url(../image/personal/header_background.png);
    background-position: center;
    background-size: cover;
    .header_inner {
      max-width: 1200px;
      height: 100%;
      margin: 0 auto;
      .img_box {
        position: relative;
        height: 100%;
        .cat {
          width: 90%;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .title_box {
        height: 100%;
        .title {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          font-weight: bold;
          margin-left: 20px;
        }
      }
    }
  }
  .main {
    padding: 20px 0;
    background-image: url(../image/personal/main_background.png);
    background-repeat: repeat;
    .main_inner {
      max-width: 1200px;
      margin: 0 auto;
      padding: 10px;
      .back_home {
        width: 100%;
        text-align: center;
        padding: 10px 0;
        border: 2px solid #45a735;
        border-radius: 10px;
        color: #45a735;
        font-size: 16px;
        background-color: #fff;
        margin-bottom: 10px;
        cursor: pointer;
        i {
          font-size: 25px;
          margin-right: 5px;
        }
      }
      .userInfo {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;
        .fullName {
          font-size: 35px;
          font-weight: bold;
          color: #fff;
        }
        .coinBox {
          display: flex;
          justify-content: start;
          align-items: center;
          span {
            font-family: Arial;
            font-size: 35px;
            color: #fff;
          }
        }
      }
    }
  }
}
// 側邊欄
.profileMenu {
  .menuList {
    margin: 0;
    padding: 10px 30px;
    list-style-type: none;
    > li {
      height: 60px;
      line-height: 60px;
      font-size: 18px;
      border-bottom: 1px solid rgba(235, 235, 235, 1);
      > a,
      > div {
        display: block;
        width: 100%;
        > img {
          width: 26px;
          height: 26px;
          margin-right: 15px;
        }
        > .label {
          display: inline-block;
          width: calc(100% - 41px);
        }
      }
    }
    li:last-child {
      border-bottom: none;
    }
  }
  @media (max-width: 767px) {
    .menuList {
      display: none;
    }
  }
}

// .imgModal {
//   :global {
//     .ant-modal-content {
//       background-color: transparent;
//     }
//   }
// }

// 金幣
.coinPage {
  min-height: 100%;
  // padding: 20px;
  // 金幣用途
  .coin {
    img {
      width: 100%;
    }
  }
  // 積分記錄
  .history {
    padding: 30px;
    .action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .coinTotal_wrap {
        padding-top: 10px;
        .total {
          font-size: 45px;
          color: #ff5000;
        }
        .sync {
          vertical-align: top;
        }
      }
      .test {
        background: #ffe6e6 0% 0% no-repeat padding-box;
        border: 1px solid #ff7575;
        border-radius: 35px;
        color: #ff7575;
      }
      .activeName {
        border-radius: 35px;
        color: #fff;
        background-color: #ff7575;
        border-color: #ff7575;
      }
    }
  }
}

.imgModal > div {
  background-color: transparent;
}

// 個人資料
.personalPage {
  .personal_detail {
    padding: 80px 40px;
    background-color: #fff;
    .avatar_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .avatar {
        width: 172px;
        height: 172px;
        object-fit: cover;
        border-radius: 50%;
        margin-bottom: 20px;
      }
      > button {
        border-color: #ff8000;
        color: #ff8000;
      }
    }
    .text_box {
      .title {
        font-size: 30px;
        color: #676767;
        text-align: center;
        margin-top: 20px;
      }
      .text {
        font-size: 18px;
        color: #676767;
      }
    }
  }
}

.settingPage {
  min-height: 100%;
  padding: 20px;
}

.gamePage {
  padding: 30px;
  img {
    width: 100%;
  }
  .title {
    color: #4a4a4a;
    font-size: 26px;
    font-weight: bold;
  }
}

.statisticsPage {
  padding: 30px;
  // min-height: 100%;
}

//親子關係
.relationship {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 281px;
  height: 100%;
  padding: 20px;
  background-image: url("../image/personal/personal/relationship_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  h2 {
    font-size: 30px;
    color: #fff;
  }
  .heart_box {
    flex-grow: 1;
  }
  .btn_box button:first-child {
    margin-right: 10px;
  }
  .btn_primary {
    background-color: #fff;
    border-color: #fff;
    color: #f15685;
  }
  .btn_active {
    background-color: #f15685;
    border-color: #f15685;
    color: #fff;
  }
  .question_icon {
    position: absolute;
    top: 20px;
    right: 20px;
    border-color: #fff;
    color: #fa97a2;
  }
  .person {
    position: absolute;
    width: 30%;
    bottom: 0px;
    right: 20px;
  }
}
// 使用時間
.use_time {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 281px;
  height: 100%;
  padding: 20px;
  background-image: url("../image/personal/personal/useTime_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  h2 {
    font-size: 30px;
    color: #fff;
  }
  h3 {
    font-size: 28px;
    color: #fff;
  }
}

@media (max-width: 575px) {
  .personalCenterRoot .header {
    height: 140px;
  }
  .personalCenterRoot .header .header_inner .title_box .title {
    margin-left: 0px;
  }
}
