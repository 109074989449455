.box {
  margin: 35px auto;
  width: 450px;
  letter-spacing: 0px;
  @media only screen and (max-width: 576px) {
    width: 320px;
    max-width: 100%;
  }

  .authCode {
    font-size: 28px;
    text-align: center;
    color: #45a735;
  }
  .codeInput {
    margin: 36px 0;
  }
  .characters {
    height: 70px;
  }
  .character {
    width: 70px;
    border: 2px solid #d6d6d6;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .characterInactive {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
  }

  .characterSelected {
    border: 2px solid #6cf;
  }

  .describe {
    text-align: center;
    font-size: 18px;
    color: #707070;
  }
  .center {
    margin: 16px auto;
    max-width: 80%;
  }
  .button {
    font-size: 18px;
    // color: #707070;
    // border: 1px solid #707070;
    margin: 8px 0;
    height: 50px;
  }
}
