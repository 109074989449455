.wrap {
  width: 100%;
  min-height: calc(100vh - 168px);
  padding: 30px 0;
  background: url(../image/bg.png);
  .inner {
    border: 2px solid #ebedf0; 
    border-radius: 20px;
    overflow: hidden;
  }
  .title {
    height: 173px;
    line-height: 90px;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    color: #fff;
    background-image: url(../image/integral/history_title.png);
    background-size: cover;
    // box-shadow: rgb(0, 0, 0) 0px 10px 10px -12px;
  }
  .main {
    padding: 0 30px 30px;
    .action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .total {
        font-size: 12px;
        >span {
          font-size: 45px;
          color: #ff5000;
          // font-weight: bold;
        }
      }
    }
  }
}