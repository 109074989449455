.root {
  > div {
    border-radius: 15px;
    overflow: hidden;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 74px;
    background-color: #2b4b80;
    padding: 0 18px;
    .title {
      color: #fff;
      width: calc(100% - 40px);
      height: 42px;
      line-height: 42px;
      // width: 100%;
    }
    .close {
      color: #fff;
    }
  }
}
