$blue:rgb(1, 88, 155);
$gray:rgb(175, 175, 175);
.CourseFinishWarp {
  .blackboardHeader {
    height: 50px;
    background: url('../image/blackboardh.png') no-repeat center center / 100% 100%;
  }
  .blackboardFooder {
    height: 50px;
    background: url('../image/blackboardf.png') no-repeat center center / 100% 100%;
  }
}

.blackboard {
  padding: 10px;
  flex-wrap:wrap;
  background: url('../image/blackboard.png');
  .item {
    position: relative;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .circle{
      width: 100%;
    }
    .avatar {
      position: absolute;
      width: 65%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @media (min-width: 992px) {
    .item {
      width: 10%;
    }
  }
  // .item:nth-child(1n) {
  //   background: url('../image/CircleB.png') no-repeat center center /90%;
  // }
  // .item:nth-child(2n) {
  //   background: url('../image/CircleG.png') no-repeat center center /90%;
  // }
  // .item:nth-child(3n) {
  //   background: url('../image/CircleR.png') no-repeat center center /90%;
  // }
}

.uploadDataBtn{
  font-size: 12px;
  color: #1890ff;
  line-height: 35px;
  cursor: pointer;
}
.uploadDataBtn:hover{
  color: rgb(24, 144, 255);
}

.festival_container{
  max-width: 1300px;
  padding-top: 30px;
  .festival_item{
    overflow: hidden;
    margin-bottom: 30px;
    padding: 10px 0;
    border: 6px solid #d5e7fb;
    border-radius: 24px;
    box-shadow: 0 4px 5px #ddd;
    .locked{
      position: absolute;
      top: 0;
      width: 100%; 
      height: 100%; 
      background: rgba(0, 0, 0, 0.7);
      z-index: 2;
      .icon_lock{
        font-size: 30px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .left{
      padding: 0 25px;
      border-right: 3px dashed #b8e2ff;
    }
    @media (max-width: 576px) {
      .left {
        border: none;
      }
    }
    .right {
      padding: 10px 25px 0;
      @media (min-width: 1200px) {
        .rCol {
          width: 20%;
        }
      }
      .mask {
        position: absolute;
        top: 0;
        font-size: 13px;
        text-align: left;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        .maskIcon {
          font-size: 28px;
          position: absolute;
          top: 38%;
          left: 38%;
        }
        span {
          width: 100%;
          padding: 0 10px;
          line-height: 35px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          position: absolute;
          bottom: 0;
          color: #fff;
        }
      }
      .isSeen_mask {
        background: none;
        span {
          background:rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
  .pic {
    position: relative;
    height: 160px;
    border-radius: 8px;
    overflow: hidden;
  }
  .mask {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 35px;
    text-align: center;
    line-height: 35px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    img {
      width: 45%;
      position: absolute;
      top: 20%;
      left: 28%;
    }
    // span {
    //   background: rgba(0, 0, 0, 0.5);
    // }
  }
}
