.drWongRoot {
  :global {
    .ant-drawer-close {
      background: #ff5287;
      border-radius: 50%;
      font-size: 30px;
      color: white;
      margin-top: 8px;
    }
  }
  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .drwongInner {
    padding-top: 50px;
    .header {
      img {
        width: 100%;
        max-width: 120px;
      }
      .title {
        font-size: 30px;
        font-weight: bold;
        margin: 0;
      }
      .answer {
        i {
          display: inline-block;
          width: 15px;
          height: 15px;
          background: url("../image/drWong/info.svg") no-repeat center center;
          background-size: contain;
          vertical-align: middle;
          margin-right: 5px;
        }
        a {
          font-size: 15px;
          color: inherit;
        }
      }
    }
    .main {
      margin-top: 20px;
      .side {
        > div {
          cursor: pointer;
          text-align: center;
          img {
            max-width: 142px;
            width: 100%;
          }
        }
      }
      // cpoy文件使用的样式
      .sideCopy {
        > div {
          cursor: pointer;
          text-align: center;
          img {
            width: 100%;
            max-height: 157.34px;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.multi {
  position: relative;
  width: 100%;
  background-color: #f3fbff;
  min-height: 100%;
  border-radius: 20px;
  padding: 40px 0;
  .btn {
    position: absolute;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    width: 80px;
    height: 80px;
    color: #fff;
    background-color: #8fd3ff;
    border-radius: 10px;
    cursor: pointer;
    > i {
      font-size: 30px;
      margin: auto;
    }
  }
  .btnLeft {
    left: -20px;
  }
  .btnRight {
    right: -20px;
  }
  .mascot {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 150px;
  }
  .inner {
    .multiSlider {
      ul li {
        margin: 0 15px;
        button:before {
          font-size: 20px;
        }
      }
      .sliderList {
        min-height: 480px;
        .imgBox {
          img {
            display: inline-block;
          }
          img:nth-child(1) {
            width: 150px;
            margin-right: 10px;
          }
          img:nth-child(2) {
            width: 30px;
            cursor: pointer;
          }
        }
        .text {
          font-size: 35px;
          font-weight: bold;
          color: #000;
          margin-top: 10px;
        }
      }
    }
  }
}

.vocabulary {
  > div {
    background-color: transparent;
  }
  .header {
    width: 100%;
    background-image: url("../image/drWong/vocab/header.png");
    background-position: center center;
    background-size: cover;
    text-align: center;
    padding: 15px;
    border-top-left-radius: 25px 25px;
    border-top-right-radius: 25px 25px;
  }
  .sliderWrap {
    min-height: 400px;
    position: relative;
    background-color: #fff;
    padding: 20px 0;
    .btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
      i {
        font-size: 60px;
        cursor: pointer;
        color: #b3e0f6;
      }
    }
    .btnLeft {
      left: 0px;
    }
    .btnRight {
      right: 0px;
    }
    .slider {
      .imgBox {
        position: relative;
        .vocabImg {
          width: 100%;
          max-height: 400px;
          object-fit: contain;
        }
        .strokeOrder {
          position: absolute;
          top: 0px;
          right: 20px;
          width: 100px;
          cursor: pointer;
        }
      }
      .videoBox {
        position: relative;
        padding: 0 60px;
        min-height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        .return {
          position: absolute;
          width: 50px;
          top: 0px;
          right: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 200px;
    background-image: url("../image/drWong/vocab/footer.png");
    background-position: center center;
    background-size: cover;
    border-bottom-left-radius: 25px 25px;
    border-bottom-right-radius: 25px 25px;
    padding: 15px;
    img {
      cursor: pointer;
    }
  }
}

@media (max-width: 1024px) {
  .multi {
    .btn {
      width: 40px;
      height: 40px;
      i {
        font-size: 20px;
      }
    }
    .mascot {
      width: 100px;
    }
  }
  .vocabulary .header img {
    max-width: 20%;
  }
  .vocabulary .footer {
    height: 125px;
  }
  .vocabulary .footer img {
    max-width: 50px;
  }
  .vocabulary .sliderWrap {
    min-height: 280px;
    .btn i {
      font-size: 30px;
    }
    .slider {
      .imgBox {
        .vocabImg {
          max-height: 280px;
        }
        .strokeOrder {
          width: 60px;
          right: 10px;
        }
      }
      .videoBox {
        min-height: 280px;
        padding: 0 30px;
        .return {
          width: 30px;
          right: 10px;
        }
      }
    }
  }
  .vocabulary .sliderWrap .slider img {
    max-height: 280px;
  }
}
