.root {
  .spin {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pagination_box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .pagination {
      > li {
        border: none;
      }
      > li:first-child, > li:last-child {
        display: none;
      }
    }
  }
}

.page_btn {
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #29ABE2;
  text-align: center;
}

.page_btn_active {
  color: #fff;
  background-color: #29ABE2;
}