.root {
  .inner {
    max-width: 1400px;
    margin: 0 auto;
    .loading {
      text-align: center;
      margin-top: 30px;
    }
    .item_wrap {
      width: 20%;
    }
  }
}