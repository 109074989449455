.root {
  max-width: 386px;
  margin: 70px auto;
  .btn {
    font-size: 18px;
    height: 54px;
  }
  .back {
    color: #707070;
  }
  .next {
    border-color: #73bc66;
    background: #73bc66 0% 0% no-repeat padding-box;
    color: #fff;
  }

  .title {
    margin-top: 24px;
    color: #45a735;
    font-weight: bolder;
  }
  .describe {
    color: #656565;
    margin-bottom: 24px;
  }
  .account {
    color: #45a735;
    text-decoration: underline;
    cursor: pointer;
  }
}
