.warp {
  background-color: #fff;
}
.fileWarp{
  min-height: 250px;
  padding: 20px;
  .icon, .fileIcon{
    font-size: 40px;
  }
  .fileItem{
    position: relative;
    height: 120px;
    border: 3px solid rgb(89, 89, 89);
    // overflow: hidden;
    cursor: pointer;
    img, .fileIcon, span, .file{
      text-align: center;
      color: #595959;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .streaming {
      position: absolute;
      top: -17px;
      left: -11px;
      font-size: 25px;
    }
    .action{
      position: absolute;
      top: 0;
      right: 0;
      .edit, .delete{
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: #595959;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .fileName{
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 5px auto;
  }
  .fileBtn{
    width: 120px; 
    height: 120px;
    margin-bottom: 10px;
  }
  .previewContainer{
    text-align: center;
    margin-bottom: 30px;
    .preview{
      width: 100%;
      // 去掉滚动条
      // overflow: auto;
      canvas{
        width: 100% !important;
        height: 100% !important;
      }
      margin-bottom: 10px;
      img, video, audio, h2{
        width: 100%;
      }
    }
    .download{
      line-height: 35px;
    }
  }
}