//小贴士组件样式
:global {
  //最外层
  .ant-modal-content {
    box-shadow: none !important;
    .ant-modal-body {
      .ant-upload {
        width: 100%;
        // height: 120px;
      }
      .ant-upload-list{
        width: 100%;
        .ant-upload-list-item{
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
.wrap {
  position: relative;
  .xs_title {
    h2 {
      background: #ff5287;
      line-height: 80px;
      color: #fff;
      font-weight: bold;
      padding-left: 30px;
      font-size: 30px;
      margin: 0;
    }
  }
  .container {
    border-radius: 4px;
    text-align: center;
    .fileWrap{
      // 去掉滚动条
      width: 100%;
      user-select: none;
      // overflow: auto; 
      margin-bottom: 10px;
       canvas{
        width: 100% !important;
        height: 100% !important;
        
      }
      img, video, audio, h2{
        width: 100%;
      }
    }
    .iconWrap{
      position: relative;
      height: 70px;
      border: 1px solid rgb(89, 89, 89);
      cursor: pointer;
      .streaming {
        position: absolute;
        top: -14px;
        left: -6px;
        font-size: 20px;
      }
      .file, .text{
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        p{
          margin: 0;
        }
      }
    }
    .fileName{
      width: 100%;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 auto;
      font-size: 12px;
      line-height: 30px;
    }
    h1 {
      color: #ff5287;
    }

    h3 {
      color: #888;
      margin-bottom:20px;
    }
    .xs_share,
    .xs_update {
      width: 60%;
      background: #ff5287;
      color: #fff;
    }
    // @media (min-width: 768px) {
    //   .xs_share,
    //   .xs_update {
    //     display: none;
    //   }
    // }
  }
  .share {
    width: 80px;
    height: 101px;
    position: absolute;
    bottom: 67px;
    right: -15px;
    border: 0;
    background: none;
  }

  .icon, .text{
    font-size: 30px;
  }

  .update {
    width: 80px;
    height: 101px;
    position: absolute;
    bottom: 180px;
    right: -12px;
    border: 0;
    background: none;
    .ant-btn[disabled]{
      background: none;
    }
  }

  .close {
    position: absolute;
    right: -19px;
    top: -14px;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    border: none;
    background: #ff5287;

    i {
      color: #fff;
      font-size: 30px;
    }
  }
  .document{
    width: 100%;
  }
  @media (max-width: 768px) {
    .close {
      top: 0;
      right: 0;
    }
    .share,
    .update {
      display: none;
    }
  }
}
