.actions-mask-enter {
  opacity: 0;
  // transform: scale(0.9);
}
.actions-mask-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 200ms;
}
.actions-mask-exit {
  opacity: 1;
}
.actions-mask-exit-active {
  opacity: 0;
  // transform: scale(0.9);
  transition: opacity 200ms, transform 200ms;
}
