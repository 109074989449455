.root {
  .main {
    min-height: 450px;
    .list_title {
      font-size: 16px;
      color: #919191;
      span {
        margin-right: 22px;
      }
      .action_btn {
        cursor: pointer;
      }
    }
    .list_description {
      font-size: 18px;
      color: #000;
    }
  }
  .btn_box {
    margin-top: 12px;
    button {
      font-size: 18px;
    }
    .submit_btn {
      min-width: 91px;
      height: 48px;
      background: #29ABE2 0% 0% no-repeat padding-box;
      color: #fff;
    }
    .cancel_btn {
      height: 48px;
      font-size: 18px;
      margin-left: 12px;
    }
  }
}