.KnowledgeFinishWarp {
  padding: 40px 0;
  .imgwarp {
    display: flex;
    align-items: center;
    padding-top: 5px;
    .item {
      margin:0 3px;
    }
    .button {
      // display: flex;
      // align-items: center;
      height: 45px;
      width: 120px;
      background-color: #656565;
      color: #fff;
    }
  }
  .chartwrap {
    border-radius: 10px;
    box-shadow: 1px 3px 4px 3px #eaeaea;
    padding: 10px;
    display: flex;
    .itemwarp:first-child {
      max-width: 30px;
    }
    .itemwarp {
      flex: 1;
      height: 100%;
      align-items:flex-end;
      display: flex;
      flex-wrap:wrap;
      align-content:flex-end;
      .item {
         width: 100%;
         height: 60px;
         border-top: 3px solid #e2e2e2;
         display: flex;
         justify-content: center;
         align-items: center;
      }
      @media(min-width:560px) {
        .item {
          height: 80px;
        }
      }
      .item:first-child{
        border:none;
      }
      .yname {
        color: #e2e2e2;
      }
      .name {
        text-align: center;
        color: rgb(79, 99, 116);
        font-weight: 700;
      }
    }
    .itemwarp:first-child {
      
      .item {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        border:none;
      }
    }
  }
}