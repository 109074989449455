.root {
  padding: 20px;
  .row {
    // max-height: 600px;
    // overflow: auto;
    .col {
      .item {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 75%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: #E3E3E3;
        .not_submitted {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 34px;
          color: #676767;
        }
        .streamer {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 33px;
          background: rgba(0, 0, 0, 0.45);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;
          > span {
            font-size: 18px;
            color: #fff;
          }
          .name {
            flex-grow: 1;
          }
          .comment {
            flex-shrink: 0;
          }
        }
      }
    }
  }
}